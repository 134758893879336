<template>
    <div class="wrap">
        <el-image :style="{ width: iwidth + 'px', height: iheight + 'px' }" :preview-src-list="[dataModel]"
            :src="dataModel" fit="cover"></el-image>
        <div v-if="des">{{ tip }}</div>
        <div v-if="des">{{ des }}</div>
        <el-upload class="upload-demo" action="" :limit="1" :http-request="diyUpload" :show-file-list="false">
            <el-button size="small" type="primary">点击更换</el-button>
        </el-upload>
    </div>
</template>

<script>

export default {
    name: 'custom-image',
    props: {
        value: {
            type: String,
            default: ''
        },
        iwidth: {
            type: String,
            default: ''
        },
        iheight: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tip: {
            type: String,
            default: ""
        },
        des: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dataModel: this.value || 'https://images.100769.xyz/file/32ee1c0f53d700cc26d4c.gif',
        }
    },
    watch: {
        value(val) {
            this.dataModel = val
        },
        dataModel(val) {
            this.$emit('input', val)
        }
    },
    methods: {
        diyUpload(file) {
            this.blobToBase64(file.file, baseText => {
                this.$emit('input', baseText)
            })
        },
        blobToBase64(blob, callback) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                callback(reader.result);
            });
            reader.readAsDataURL(blob);
        }
    },
}
</script>

<style lang="scss" scoped>
.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
.upload-demo{
    margin-left: 20px;
}
</style>