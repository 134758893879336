import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import FormMaking from '@/lib/formMaking/FormMaking.umd'
import '@/lib/formMaking/FormMaking.css'
import CustomImage from '@/components/CustomImage.vue'

Vue.use(ElementUI).use(FormMaking, {
  components: [
    {
      name: 'custom-image',
      component: CustomImage
    }
  ]
});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
