<template>
  <div class="home">
    <fm-making-form ref="makingform" preview generate-code generate-json :custom-fields="customFields">
      <template #widgetconfig="{ type, data, customProps }">
        <template v-if="type === 'custom' && data.el === 'custom-image'">
          <el-form-item label="图片描述">
            <el-input v-model="customProps.des"></el-input>
          </el-form-item>
          <el-form-item label="宽度">
            <el-input v-model="customProps.iwidth"></el-input>
          </el-form-item>
          <el-form-item label="高度">
            <el-input v-model="customProps.iheight"></el-input>
          </el-form-item>
        </template>

      </template>
    </fm-making-form>
  </div>
</template>

<script>


export default {
  name: 'HomeView',
  components: {

  },
  data() {
    return {
      customFields: [
        {
          name: '图片组件',
          el: 'custom-image',
          options: {
            defaultValue: '',
            labelWidth: 0,
            isLabelWidth: false,
            hidden: false,
            dataBind: true,
            validator: '',
            //扩展属性 必须是json格式配置
            extendProps: {

            },
            //自定义属性
            customProps: {
              iwidth: '150',
              iheight: '150',
              des: ''
            }
          },
          events: {
            onLoad: '' // 定义设计器可以配置的事件，处理组件 emit 的事件
          }
        }
      ]
    }
  },
}
</script>
<style lang="scss">
.home {
  height: 100%;
}
</style>
